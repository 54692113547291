<template>
  <section class="base-page">
    <PageHeader class="base-page-header base-shadow"> </PageHeader>
    <basic-table
      :loading="tableLoading"
      :tableColumns="tableColumns"
      :tableData="tableData"
      :page="page"
      :size="size"
      :total="total"
      :orderDirection="orderDirection"
      :orderProp="orderProp"
      @onSortChange="onTableSortChange"
      @onSizeChange="handleSizeChange"
      @onPageChange="handlePageChange"
      @onRefresh="onRefresh"
      @onClearAndRefresh="onClearAndRefresh"
      @onSelectionChange="onSelectionChange"
    >
      <!-- <template #tableControl></template> -->
      <template v-for="column in tableColumns">
        <el-table-column
          v-if="column.prop !== 'controlColumn' && column.showColumn"
          v-bind="column"
          :show-overflow-tooltip="true"
          :key="column.prop"
        >
          <template slot-scope="scope">
            <span v-if="column.prop === 'createdAt'">{{
              scope.row.createdAt | date
            }}</span>

            <span v-else-if="column.prop === 'disabled'">
              <el-tag type="success" v-if="!scope.row.disabled">正常</el-tag>
              <el-tag type="danger" v-if="scope.row.disabled">已禁用</el-tag>
            </span>
            <span v-else>{{ scope.row[column.prop] }}</span>
          </template>
        </el-table-column>
        <!-- <el-table-column
          v-if="column.prop === 'controlColumn' && column.showColumn"
          v-bind="column"
          :key="column.prop"
        >
          <template slot-scope="scope">
            <el-button
              v-if="checkPermission(['CLIENT_ADMIN'])"
              @click.native.prevent="editRow(scope.row, scope.$index)"
              type="text"
              size="small"
            >
              修改
            </el-button>
          </template>
        </el-table-column> -->
      </template>
    </basic-table>
  </section>
</template>

<script>
import BasicTable from "@/components/BasicTable";
import { GetClientOwnersList } from "./api";
import { ShowApiError } from "@/request/error";
import { isEmprty } from "@/utils/validate";
import { checkPermission } from "@/utils/auth";
import { getOrderType } from "@/utils/common";
import PageHeader from "@/components/PageHeader";
import config from "@/config";
import { ReadLocalColumn } from "@/utils/table";
export default {
  components: {
    BasicTable,
    PageHeader,
  },
  data() {
    return {
      tableLoading: false,
      deleteLoading: false,
      tableColumns: [],
      page: 0,
      size: config.TABLE_SIZE,
      total: 0,
      tableData: [],
      tableFilters: {},
      orderProp: "createdAt",
      orderDirection: "descending",
      currentId: "",
      multipleSelected: [],
    };
  },
  created() {
    this.buildTableColumns();
    this.getTableData();
  },
  methods: {
    isEmprty,
    checkPermission,
    buildTableColumns(columns = []) {
      if (columns.length > 0) {
        this.tableColumns = columns;
      } else {
        let columns = [
          {
            prop: "id",
            label: "ID",
            showColumn: true,
            width: 100,
            align: "center",
            sortable: "custom",
          },
          {
            prop: "disabled",
            label: "启/禁状态",
            showColumn: true,
            width: 120,
            align: "center",
            sortable: "custom",
          },
          {
            prop: "name",
            label: "用户姓名",
            showColumn: true,
            sortable: "custom",
          },
          {
            prop: "departmentName",
            label: "部门",
            showColumn: true,
            align: "left",
            sortable: "custom",
          },
          
          {
            prop: "createdAt",
            label: "创建日期",
            showColumn: true,
            width: 120,
            align: "center",
            sortable: "custom",
          },
          {
            prop: "createdByName",
            label: "创建人",
            align: "left",
            showColumn: true,
            width: 120,
            sortable: "custom",
          },
          {
            prop: "controlColumn",
            fixed: "right",
            showColumn: true,
            label: "操作",
            width: "120",
          },
        ];
        this.tableColumns = ReadLocalColumn(this.$route, columns)
      }
    },
    handleSizeChange(val) {
      this.page = 0;
      this.size = val;
      this.getTableData();
    },
    handlePageChange(val) {
      this.page = val - 1;
      this.getTableData();
    },
    filterParams() {
      return {};
    },
    getTableOrderParams() {
      if (this.orderProp !== "" && this.orderDirection !== null) {
        return `${this.orderProp},${getOrderType(this.orderDirection)}`;
      } else {
        return null;
      }
    },
    getTableData() {
      this.tableLoading = true;
      GetClientOwnersList({
        page: this.page,
        size: this.size,
        sort: this.getTableOrderParams(),
        ...this.filterParams(),
      })
        .then((res) => {
          let { content, totalElements } = res.data;
          this.tableData = content.map((item) => {
            return {
              ...item,
              isEdit: false,
            };
          });
          this.total = totalElements;
          // 加载状态结束
          this.tableLoading = false;
        })
        .catch((err) => {
          this.tableLoading = false;
          ShowApiError("拉取列表错误", err);
        });
    },
    onRefresh() {
      this.page = 0;
      this.getTableData();
    },
    onClearAndRefresh() {
      this.orderProp = "createdAt";
      this.orderDirection = "descending";
      this.onResetFilter();
      this.onRefresh();
    },
    onResetFilter() {
      for (const key in this.tableFilters) {
        if (typeof this.tableFilters[key] === "object") {
          if (this.tableFilters[key] instanceof Array) {
            this.tableFilters[key] = [];
          } else {
            this.tableFilters[key] = null;
          }
        } else {
          this.tableFilters[key] = "";
        }
      }
    },
    onTableSortChange({ prop, order }) {
      if (order === null) {
        this.orderProp = "createdAt";
        this.orderDirection = "descending";
      } else {
        this.orderProp = prop;
        this.orderDirection = order;
      }
      this.onRefresh();
    },
    onSelectionChange(selections) {
      this.multipleSelected = selections;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>